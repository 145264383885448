import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["total", "item", "summary", "nextButton", "summaryItemTemplate"]

  updateTotal() {
    let totalPrice = 0;
    let hasTicketsSelected = false;

    this.clearSummary();

    this.itemTargets.forEach(item => {
      const { quantity, price, title } = this.extractItemDetails(item);

      if (quantity > 0) {
        this.addSummaryItem(quantity, title, price);
        totalPrice += quantity * price;
        hasTicketsSelected = true;
      }
    });

    this.updateUI(totalPrice, hasTicketsSelected);
  }

  clearSummary() {
    this.summaryTarget.innerHTML = "";
  }

  extractItemDetails(item) {
    const quantity = parseInt(item.querySelector('select').value, 10);
    const price = parseFloat(item.dataset.priceValue);
    const title = item.dataset.titleValue;
    return { quantity, price, title };
  }

  addSummaryItem(quantity, title, price) {
    const templateContent = this.summaryItemTemplateTarget.content;
    const clone = document.importNode(templateContent, true);
    clone.querySelector("[data-item-title]").textContent = `${quantity} x ${title}`;
    clone.querySelector("[data-item-price]").textContent = `$${(quantity * price).toFixed(2)}`;
    this.summaryTarget.appendChild(clone);
  }

  updateUI(totalPrice, hasTicketsSelected) {
    this.totalTarget.textContent = `$${totalPrice.toFixed(2)}`;
    this.nextButtonTarget.disabled = !hasTicketsSelected;
  }
}