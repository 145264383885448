import { Controller } from "@hotwired/stimulus"
import { Turbo } from '@hotwired/turbo-rails'

import { get, post, put, patch, destroy } from '@rails/request.js'

export default class extends Controller {
  static values = {
    id: Number,
    frame: String
  }
  click() {
    console.log('click')
    console.log(this.frameValue || '_top')
    // Turbo.visit('/staff/students/' + this.idValue, { frame: this.frameValue || '_top' })
    // get('/staff/students/' + this.idValue)
    Turbo.visit('/staff/students/' + this.idValue)
  }
}
