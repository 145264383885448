import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("ErrorSummary controller connected");
  }

  focusInput(event) {
    event.preventDefault(); // Prevent the default link behavior
    const targetId = event.currentTarget.getAttribute('href').replace('#', '');
    const inputElement = document.getElementById(targetId);
    console.log(inputElement);
    if (inputElement && inputElement.isVisible) {
      inputElement.scrollIntoView();
      inputElement.focus({ preventScroll: true });
    } else {
      console.log('Input element not found (visible)');
      console.log(targetId);
      const jumpToElement = document.querySelector('[id^="' + targetId + '"]');
      console.log(jumpToElement);
      jumpToElement.scrollIntoView();
      jumpToElement.focus({ preventScroll: true });
      console.log(inputElement);
      // console.log(inputElement.label);
      const parentElement = inputElement.parentElement
      // const labelElement = document.querySelector('label[for="' + targetId + '"]')
      console.log(parentElement)
      // const firstVisibleInput = parentElement.querySelector('input:not([type="hidden"])');
      // console.log('First visible input');
      // console.log(firstVisibleInput);
      // console.log(labelElement);
      //   if (firstVisibleInput) {
      //     firstVisibleInput.scrollIntoView();
      //     firstVisibleInput.focus({ preventScroll: true });
      //   }
    }
  }

  // clickEvent(event) {
  //   const inputElement =
  // }
}
