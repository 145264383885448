import {ActionEvent, Controller} from "@hotwired/stimulus"

// Connects to data-controller="select-all"
export default class extends Controller {
  hasCheckboxAllTarget: boolean
  checkboxTargets: HTMLInputElement[]
  checkboxAllTarget: HTMLInputElement

  static targets: string[] = ['checkboxAll', 'checkbox']

  initialize () {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  checkboxAllTargetConnected (checkbox: HTMLInputElement): void {
    checkbox.addEventListener('change', this.toggle)

    this.refresh()
  }

  checkboxTargetConnected (checkbox: HTMLInputElement): void {
    checkbox.addEventListener('change', this.refresh)

    this.refresh()
  }

  checkboxAllTargetDisconnected (checkbox: HTMLInputElement): void {
    checkbox.removeEventListener('change', this.toggle)

    this.refresh()
  }

  checkboxTargetDisconnected (checkbox: HTMLInputElement): void {
    checkbox.removeEventListener('change', this.refresh)

    this.refresh()
  }

  toggle (e: Event): void {
    console.log(e)
    e.preventDefault()

    this.checkboxTargets.forEach(checkbox => {
      // @ts-ignore
      checkbox.checked = e.target.checked
      this.triggerInputEvent(checkbox)
    })
  }

  toggleCheckbox(e: ActionEvent): void {
    console.log(e.params.checkboxId)

    this.checkboxTargets.forEach(checkbox => {
      // @ts-ignore
      if (checkbox.value == e.params.checkboxId) {
        checkbox.checked = !checkbox.checked
        this.triggerInputEvent(checkbox)
        this.refresh()
      }
    })
  }

  refresh (): void {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }

  triggerInputEvent (checkbox: HTMLInputElement): void {
    const event = new Event('input', { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }

  get checked (): HTMLInputElement[] {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked (): HTMLInputElement[] {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}