import { Controller } from "@hotwired/stimulus"
import Toast from 'bootstrap/js/dist/toast'  

export default class extends Controller {
  static values = { closeUrl: String }
  initialize() {
    this.toast = new Toast(this.element, { autohide: true, delay: 4500 })
    this.toast.show()
  }
  
  disconnect() {
    this.toast.hide()
  }
}
