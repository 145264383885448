import { Controller } from "@hotwired/stimulus"
import Fuse from 'fuse.js';

export default class extends Controller {
  static targets = ['attendee', 'searchInput'];

  connect() {
    this.initializeFuse();
  }

  initializeFuse() {
    const attendees = this.attendeeTargets.map((element) => ({
      id: element.id,
      name: element.querySelector('.attendee-info h5').textContent,
      initials: this.getInitials(element.querySelector('.attendee-info h5').textContent),
      element,
    }));

    this.fuse = new Fuse(attendees, {
      keys: [
        { name: 'name', weight: 0.7 },
        { name: 'initials', weight: 0.3 },
      ],
      threshold: 0.4,
    });
  }

  getInitials(name) {
    const [firstName, lastName] = name.split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toLowerCase();
  }

  search() {
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();

    if (searchTerm === '') {
      this.attendeeTargets.forEach((element) => {
        element.style.display = 'block';
      });
    } else {
      const results = this.fuse.search(searchTerm);

      this.attendeeTargets.forEach((element) => {
        element.style.display = 'none';
      });

      results.sort((a, b) => a.score - b.score);

      results.forEach(({ item }) => {
        this.element.appendChild(item.element);
        item.element.style.display = 'block';
      });
    }
  }
}