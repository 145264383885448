import { Controller } from "@hotwired/stimulus"

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import {BootstrapTheme} from "@fullcalendar/bootstrap5/internal";
export default class extends Controller {
  static values = { events: Array, config: {type: Object, default: {}}, hideToolbar: Boolean }
  connect() {
    console.log(this.eventsValue)
    let calendarEl = this.element;
    if (this.config === undefined) {
      this.config = this.configValue;
    }
    if (this.hideToolbarValue) {
      this.config.headerToolbar = {
        left: '',
        center: '',
        right: ''
      };
    }
    console.log(this.config)
    BootstrapTheme.prototype.classes.button = 'btn btn-neutral btn-sm shadow-none';
    let calendar = new Calendar(calendarEl, Object.assign({
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin, interactionPlugin, multiMonthPlugin ],
      themeSystem: 'bootstrap5',
      allDaySlot: false,
      slotMinTime: '08:00:00',
      slotMaxTime: '20:00:00',
      contentHeight: 'auto',
      initialView: 'wday',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,listWeek,wday'
      },
      views: {
        wday: {
          type: 'timeGridWeek',
          hiddenDays: [0, 6],
          title: 'Week',
          buttonText: 'week',
          dayHeaderFormat: {
            weekday: 'long'
          }
        }
      },
      defaultView: 'wday',
      select: this['select'].bind(this),
      events: this.eventsValue
    }, this.config));
    // console.log(this.element);
    calendar.render();

    this.calendar = calendar;

    console.log(calendar)
  }

  select(info) {
    console.log(info)
  }

  disconnect() {
    this.calendar.destroy()
  }
}
