import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select', 'input']
  connect() {
    this.inputTarget.classList.add('d-none')
    if (this.selectTarget.value == 'Other') {
      this.inputTarget.classList.remove('d-none')
    } else {
      this.inputTarget.classList.add('d-none')
    }
  }

  sel (e){
    if (e.target.value == 'Other') {
      this.inputTarget.classList.remove('d-none')
    } else {
      this.inputTarget.classList.add('d-none')
    }
  }
}
