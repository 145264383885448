import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visitor-contact"
export default class extends Controller {

  static targets = [ "destroy", "toggle" ]

  static values = { showStateOnConnect: { type: Boolean, default: true } }

  connect() {
    console.log('visitor-contact connected')
    console.log(this.destroyTarget.checked)
    if (this.destroyTarget.checked && this.showStateOnConnectValue) {
      this.toggleTarget.classList.add('btn-success')
      this.toggleTarget.classList.remove('btn-neutral')
    }
  }

  remove() {
    // this.element.remove()
    console.log(this.destroyTarget.checked)
    this.destroyTarget.checked = !this.destroyTarget.checked
    this.toggleTarget.classList.toggle('btn-neutral')
    this.toggleTarget.classList.toggle('btn-success')
  }
}
