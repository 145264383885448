// app/javascript/controllers/country_select_controller.js

import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="country-select"
export default class extends Controller {
  static values = { attendee: String }
  update_states() {
    // alert(this.attendeeValue)
    console.log('update_states')
    const country_code = this.element.value
    post('/countries', {
      query: { country_code: country_code, attendee: this.attendeeValue },
      responseKind: 'turbo-stream'
    })
  }
}
