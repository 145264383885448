import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    const options = this.optionsValue || {}

    if (typeof options.render !== 'undefined') {
      if (typeof options.render.option === 'string') {
        options.render.option = new Function('data', 'escape', options.render.option)
      }
      if (typeof options.render.item === 'string') {
        options.render.item = new Function('data', 'escape', options.render.item)
      }
    }

    const mergedOptions = {
      plugins: ['remove_button'],
      ...options
    }
    
    new TomSelect(this.element, mergedOptions)
  }
}