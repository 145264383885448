import { TurboMountController } from "turbo-mount";
import {Component, createElement} from "react";
import {hydrateRoot} from "react-dom/client";

export default class extends TurboMountController {
  static targets = ["mount", "form", "props"];
  
  connect() {
    super.connect()
  }

  get componentProps() {
    return {
      ...this.propsValue
    }
  }

  async mountComponent(el, Component, props) {
    const root = hydrateRoot(el, createElement(Component, props));
    return () => {
      root.unmount()
    }
  }
}