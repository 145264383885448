import { Controller } from "@hotwired/stimulus"
// import { Modal } from 'bootstrap'

export default class extends Controller {

    static targets = [ "source", "filterable" ]

    filter(event) {
        console.log(event.target.value)
        let lowerCaseFilterTerm = event.target.value.toLowerCase()
        console.log(this.filterableTargets)
        this.filterableTargets.forEach((el, i) => {
            console.log(el)
            let filterableKey =  el.getAttribute("data-filter-key")

            el.classList.toggle("d-none", !filterableKey.includes( lowerCaseFilterTerm ) )
        })
    }
}
