import { Controller } from "@hotwired/stimulus"
import CalendarController from "./calendar_controller";

import { get, post, put, patch, destroy } from '@rails/request.js'

// Connects to data-controller="date-select"
export default class extends CalendarController {

  static values = { events: Array, config: Object, startsAt: String, endsAt: String}
  static targets = [ "form", "deleteButton" ]

  initialize() {
    console.log('CONNECT')
    let startsAt = this.startsAtValue
    let endsAt = this.endsAtValue
    let config = {
      selectable: true,
      selectOverlap: false,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'semester,list'
      },
      initialView: 'semester',
      views: {
        semester: {
          type: 'multiMonth',
          visibleRange: {
            start: startsAt,
            end: endsAt
          },
          title: 'Semester',
          buttonText: 'Semester'
        }
      },
      eventClick: (info) => {
        info.jsEvent.preventDefault()
        console.log('event click')
        // Turbo.visit(info.event.url, { frame: 'modal' })
        // destroy(info.event.url).then(r => {
        //   this.reconnect()
        // })
        this.deleteButtonTarget.href = info.event.url
        this.deleteButtonTarget.click()
      },
      eventDrop: (event) => {
        this.submitForm(event.event.url, event.event)
      },
      eventResize: (event) => {
        this.submitForm(event.event.url, event.event)
      }
    }
    this.config = Object.assign( this.configValue, config )
    console.log(this.config)
    super.connect()
  }

  reconnect() {
    this.calendar.destroy()
    this.initialize()
  }

  disconnect() {
    console.log('DISCONNECT')
    super.disconnect()
  }

  select(event) {
    console.log(event)
    // this.calendar.addEvent({ title: 'dynamic event', start: event.start, end: event.end, allDay: true, display: 'list-item', color: 'green'})
    document.getElementById('session_holiday_date').value = event.start
    // document.getElementById('session_holiday_session_id').value = 1
    console.log(this.formTarget)
    this.formTarget.requestSubmit()
  }

  submitForm(action, event) {
    this.formTarget.action = action
    this.formTarget.method = 'PATCH'
    document.getElementById('availability_starts_at').value = event.start
    document.getElementById('availability_ends_at').value = event.end
    document.getElementById('availability_session_id').value = 1
    this.formTarget.requestSubmit()
  }
}
