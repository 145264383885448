import {Controller} from "@hotwired/stimulus"
import DataTable from 'datatables.net-dt'

export default class extends Controller {
  static values = {
    config: Object
  }
  initialize() {
    console.log("initialize")
    if (this.element.isDataTable) return
    this.dt = new DataTable(this.element, Object.assign({
      paging: false,
      stateSave: true,
      columnDefs: [
        { targets: 'no-sort', orderable: false },
        { targets: 'overflow-newline', className: 'overflow-newline' }
      ],
      language: {
        search: "",
        searchPlaceholder: "Search"
      }
    }, this.configValue))
    document.addEventListener("turbo:before-cache", function() {
      if (this.dt == null) return
      this.dt.destroy()
      this.dt = null
    }.bind(this))
  }
}
