import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['footer'];

  connect() {
    this.updateFooterVisibility();
  }

  updateFooterVisibility() {
    if (!this.hasFooterTarget) {
      return;
    }
    const eventOrderId = new URLSearchParams(window.location.search).get('event_order_id');
    this.footerTarget.classList.toggle('d-none', eventOrderId !== null);
  }
}