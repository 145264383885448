import { Controller } from "@hotwired/stimulus"
import Modal from 'bootstrap/js/dist/modal'
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { closeUrl: String }
  initialize() {
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  removeParams() {
    let url = new URL(location.href);
    url.searchParams.delete('attendee_id')
    history.pushState({}, '', url);
    this.modal.hide()
  }
  disconnect() {
    this.modal.hide()
  }
}
